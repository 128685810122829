import { reportClientError } from '/machinery/reportClientError'

export function pushToDataLayer(data) {
  window.dataLayer = window.dataLayer || [];
  [].concat(data).forEach(x => {
    try {
      if (!x) throw new Error(`No data given to pushToDataLayer function`)
      window.dataLayer.push(removeUndefinedValues(x))
    } catch (e) {
      reportClientError(e)
    }
  })
}

function removeUndefinedValues(data) {
  return JSON.parse(JSON.stringify(data))
}

export function trackInteraction({ title, action, type, extraInteractionData = {}, extraMetaData = {}, index = undefined } ) {
  pushToDataLayer({
    event: 'interaction',
    metadata: {
      interaction: {
        title,
        action,
        type,
        index,
        ...extraInteractionData
      },
      ...extraMetaData,
    }
  })
}
