import { useCookieConfiguration } from '@kaliber/cookie-consent'

import { routeMap } from '/routeMap'
import { useClientConfig } from '/machinery/ClientConfig'
import { pushToDataLayer } from '/machinery/tracking/pushToDataLayer'
import { cookieConfigurationEvent } from '/machinery/tracking/cookieConfiguration'
import { useTranslate, useLanguage } from '/machinery/I18n'
import { useReportError } from '/machinery/ReportError'

import { ContainerLg } from '/features/buildingBlocks/Container'
import { Link } from '/features/buildingBlocks/Link'

import styles from './CookieBar.css'

export function CookieBar({ initialConfiguration, layoutClassName = undefined }) {
  const { __ } = useTranslate()
  const language = useLanguage()
  const clientConfig = useClientConfig()
  const reportError = useReportError()

  const {
    configuration,
    acceptAll,
    rejectAll,
  } = useCookieConfiguration({
    availablePermissions: clientConfig.cookieConfiguration.availablePermissions,
    cookieEndpoint: routeMap.api.v1.cookieConfiguration(),
    initialConfiguration,
    onChange: pushCookieConsent,
    onError: reportError
  })

  const displayConsent = configuration?.show ?? false

  return displayConsent ? (
    <aside className={cx(styles.component, layoutClassName)}>
      <ContainerLg>
        <div className={styles.container}>
          <h3 className={styles.title}>{__`cookie-bar-title`}</h3>
          <p>{__`cookie-bar-text`}</p>
          <Link href={routeMap.app.cookiePolicy({ language })} label={__`cookie-bar-manage`} dataX='manage-cookies' />
          <Actions {...{ acceptAll, rejectAll }} />
        </div>
      </ContainerLg>
    </aside>

  ) : <aside style={{ display: 'none' }} />
}

function Actions({ acceptAll, rejectAll }) {
  const { __ } = useTranslate()

  return (
    <div className={styles.componentActions}>
      <button type='button' onClick={acceptAll} data-x='accept-cookies' className={styles.button}>
        {__`cookie-consent-accept`}
      </button>
      <button type='button' onClick={rejectAll} data-x='reject-cookies' className={styles.button}>
        {__`cookie-consent-reject`}
      </button>
    </div>
  )
}
function pushCookieConsent(configuration) {
  pushToDataLayer(cookieConfigurationEvent(configuration))
}
