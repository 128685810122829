import styles from './Container.css'

export function ContainerSm({ children }) {
  return <ContainerBase className={styles.componentSm} {...{ children }} />
}

export function ContainerMd({ children, layoutClassName = undefined }) {
  return <ContainerBase className={cx(styles.componentMd, layoutClassName)} {...{ children }} />
}

export function ContainerLg({ children, layoutClassName = undefined }) {
  return <ContainerBase className={cx(styles.componentLg, layoutClassName)} {...{ children }} />
}

export function ContainerXl({ children }) {
  return <ContainerBase className={styles.componentXl} {...{ children }} />
}

function ContainerBase({ children, className }) {
  return (
    <div className={cx(styles.componentBase, className)}>
      <div className={styles.layout}>
        {children}
      </div>
    </div>
  )
}
