import styles from './Link.css'

export function Link({ href, label, dataX, layoutClassName = undefined }) {
  const protocols = ['http', 'https', 'tel', 'mailto']
  const hasProtocol = protocols.some(x => href.startsWith(x))
  const target = hasProtocol ? '_blank' : '_self'
  const rel = hasProtocol ? 'noopener noreferrer' : null

  return (
    <a data-x={dataX} className={cx(styles.component, layoutClassName)} {...{ href, rel, target }}>
      {label}
    </a>
  )
}
